import React from "react"
import { LoadMoreFn, useFragment } from "react-relay"
import { graphql, OperationType } from "relay-runtime"
import { GlobalCollectionCard } from "@/components/collections/GlobalCollectionCard/GlobalCollectionCard.react"
import { Gallery, GalleryProps } from "@/design-system/Gallery"
import { loadNextToLoadMore } from "@/design-system/ScrollingPaginator"
import {
  CollectionsList_data$data,
  CollectionsList_data$key,
} from "@/lib/graphql/__generated__/CollectionsList_data.graphql"
import { CollectionsList_ListItem_data$key } from "@/lib/graphql/__generated__/CollectionsList_ListItem_data.graphql"

type Props = {
  collections?: CollectionsList_data$key
  hasNext: boolean
  isLoadingNext: boolean
  loadNext: LoadMoreFn<OperationType>
}

const PAGE_SIZE = 12
const CARD_MIN_WIDTH = 330
const CARD_HEIGHT = 400

type ItemComponentType = GalleryProps<{
  collection: CollectionsList_data$data[number] | undefined
}>["renderItem"]

const ListItem: ItemComponentType = ({
  data: { collection: collectionKey },
}) => {
  const collection = useFragment(
    graphql`
      fragment CollectionsList_ListItem_data on CollectionType
      @argumentDefinitions(
        showContextMenu: { type: "Boolean!" }
        showStats: { type: "Boolean!" }
      ) {
        relayId
        ...GlobalCollectionCard_data
          @arguments(showContextMenu: $showContextMenu, showStats: $showStats)
      }
    `,
    (collectionKey ?? null) as CollectionsList_ListItem_data$key | null,
  )

  return collection ? (
    <GlobalCollectionCard collection={collection} />
  ) : (
    <GlobalCollectionCard.Skeleton />
  )
}

export const CollectionsList = ({
  collections: collectionsKey,
  hasNext,
  isLoadingNext,
  loadNext,
}: Props) => {
  const collections = useFragment(
    graphql`
      fragment CollectionsList_data on CollectionType
      @argumentDefinitions(
        showContextMenu: { type: "Boolean!" }
        showStats: { type: "Boolean!" }
      )
      @relay(plural: true) {
        relayId
        ...CollectionsList_ListItem_data
          @arguments(showContextMenu: $showContextMenu, showStats: $showStats)
      }
    `,
    collectionsKey ?? null,
  )

  const numSkeletons = hasNext ? PAGE_SIZE : 0

  const items = Array(
    collections ? collections.length + numSkeletons : PAGE_SIZE,
  )
    .fill({})
    .map((_, i) => ({ collection: collections ? collections[i] : undefined }))

  return (
    <Gallery
      getKey={(item, index) => item.collection?.relayId || index}
      gridGap={24}
      isItemLoaded={(index, items) => !!items[index]?.collection}
      itemHeightEstimate={CARD_HEIGHT}
      itemMinWidth={CARD_MIN_WIDTH}
      items={items}
      overscanBy={1}
      pagination={{
        disableLoader: true,
        page: {
          loadMore: count => loadNextToLoadMore({ loadNext, count }),
          isLoading: () => isLoadingNext,
          hasMore: () => hasNext,
        },
        size: PAGE_SIZE,
      }}
      renderItem={ListItem}
      threshold={1}
      variant="grid"
    />
  )
}
