/**
 * @generated SignedSource<<b4324b9a4cdd6e9ce35133b2cc719691>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionsList_ListItem_data$data = {
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"GlobalCollectionCard_data">;
  readonly " $fragmentType": "CollectionsList_ListItem_data";
};
export type CollectionsList_ListItem_data$key = {
  readonly " $data"?: CollectionsList_ListItem_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionsList_ListItem_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showStats"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionsList_ListItem_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showContextMenu",
          "variableName": "showContextMenu"
        },
        {
          "kind": "Variable",
          "name": "showStats",
          "variableName": "showStats"
        }
      ],
      "kind": "FragmentSpread",
      "name": "GlobalCollectionCard_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "3a6777fbd3f8b8b2377e4b90d54acdf9";

export default node;
