import React from "react"
import { usePaginationFragment, graphql } from "react-relay"
import { TrendingCollectionsList_data$key } from "@/lib/graphql/__generated__/TrendingCollectionsList_data.graphql"
import { TrendingCollectionsListQuery } from "@/lib/graphql/__generated__/TrendingCollectionsListQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { CollectionsList } from "./CollectionsList.react"
import { getShowCollectionCard } from "./GlobalCollectionCard/GlobalCollectionCard.react"

type Props = {
  dataKey: TrendingCollectionsList_data$key | null
}

export const TrendingCollectionsList = ({ dataKey }: Props) => {
  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    TrendingCollectionsListQuery,
    TrendingCollectionsList_data$key
  >(
    graphql`
      fragment TrendingCollectionsList_data on Query
      @argumentDefinitions(
        categories: { type: "[CategorySlug!]" }
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 12 }
        chains: { type: "[ChainScalar!]" }
      )
      @refetchable(queryName: "TrendingCollectionsListQuery") {
        trendingCollections(
          categories: $categories
          after: $cursor
          first: $count
          chains: $chains
        ) @connection(key: "TrendingCollectionsList_trendingCollections") {
          edges {
            node {
              ...CollectionsList_data
                @arguments(showContextMenu: false, showStats: false)
              logo
              banner
            }
          }
        }
      }
    `,
    dataKey,
  )

  return (
    <CollectionsList
      collections={
        data
          ? getNodes(data.trendingCollections).filter(collection =>
              getShowCollectionCard(collection.logo, collection.banner),
            )
          : undefined
      }
      hasNext={hasNext}
      isLoadingNext={isLoadingNext}
      loadNext={loadNext}
    />
  )
}
