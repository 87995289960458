/**
 * @generated SignedSource<<8b18c148258116e997c75b74a6c1a261>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionsList_data$data = ReadonlyArray<{
  readonly relayId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionsList_ListItem_data">;
  readonly " $fragmentType": "CollectionsList_data";
}>;
export type CollectionsList_data$key = ReadonlyArray<{
  readonly " $data"?: CollectionsList_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionsList_data">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "showStats"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CollectionsList_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "showContextMenu",
          "variableName": "showContextMenu"
        },
        {
          "kind": "Variable",
          "name": "showStats",
          "variableName": "showStats"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CollectionsList_ListItem_data"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "e331d62c677ab05e94496068119df9ac";

export default node;
